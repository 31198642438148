import { render, staticRenderFns } from "./ysm-latest-order.vue?vue&type=template&id=226680a6&scoped=true&"
import script from "./ysm-latest-order.vue?vue&type=script&lang=js&"
export * from "./ysm-latest-order.vue?vue&type=script&lang=js&"
import style0 from "./ysm-latest-order.vue?vue&type=style&index=0&id=226680a6&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "226680a6",
  null
  
)

export default component.exports