<template>
	<div class="ysm-latest-order">

		<div class="order">
			<div class="conditions" align="left">
				<div id="createNewOrder" @click="goAddOrder">
					<img  src="../../assets/ysmimg/latestaddorder.jpg" style="margin-right: 5px;height: 73px; width: 80px;margin-left: 10px;cursor: pointer;"  />
				    <span>新建订单</span>
				</div>
				
				<div style="margin-top: 10px;padding-left: 20px;">
				<el-radio-group style="display: none;" v-model="radioValue" @change="changeFenLei">
					<el-radio class="yr" :label="0">所有订单</el-radio>
					<el-radio class="yr" :label="1">未确认订单</el-radio>
					<el-radio class="yr" :label="2">不可制作订单</el-radio>
					<el-radio class="yr" :label="3">制作前订单</el-radio>
					<el-radio class="yr" :label="4">制作中订单</el-radio>
					<el-radio class="yr" :label="5">制作完成订单</el-radio>
				</el-radio-group>
				<el-form style="padding-top: 0.825rem;padding-bottom: 0rem;" :inline="true" :model="conditionForm"
					class="demo-form-inline">
					<el-form-item>
						<el-input v-model="conditionForm.query" placeholder="订单ID号/患者编号"></el-input>
					</el-form-item>
					<el-form-item>
						<el-select v-model="conditionForm.status1" clearable placeholder="状态">
							<el-option v-for="(item,index) in statusOptions" :label="item.label" :value="item.value">
								
							</el-option>
							<!-- <el-option label="订单未提交" value="0"></el-option>
							<el-option label="订单审核中" value="1"></el-option>
							<el-option label="订单未通过" value="2"></el-option>
							<el-option label="设计方案确认中" value="3"></el-option>
							<el-option label="订单待付款" value="4"></el-option>
							<el-option label="订单生产中" value="5"></el-option>
							<el-option label="订单发货中" value="6"></el-option> -->
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button class="primary" type="primary" @click="onSubmit">搜索</el-button>
					</el-form-item>
				</el-form>
				</div>
			</div>
			<div id="a" align="center" style="margin-top: 1.875rem;">
				<el-table :row-style="changeRed" :data="ordersData.slice((currentPage-1)*pagesize,currentPage*pagesize)" style="width: 80%"
					:default-sort="{prop: 'date', order: 'descending'}">
					<el-table-column align="center" prop="orderNum" label="订单ID号">
						<template slot-scope="scope">
							<span :class="{big:scope.row.unread==2}" style="cursor: pointer !important;" @click="editOrder(scope.row.oid,scope.row.zhaungtai)"
								v-if="scope.row.bianji==true"><i
									class="el-icon-edit-outline"></i>&nbsp;{{scope.row.orderNum}}</span>
							<span :class="{big:scope.row.unread==2}" v-if="scope.row.bianji==false">&nbsp;&nbsp;&nbsp;&nbsp;{{scope.row.orderNum}}</span>
						</template>
					</el-table-column>
					<el-table-column align="center" prop="patientNum" label="患者编号">
					</el-table-column>
					<!-- <el-table-column align="center" prop="fenlei" label="订单分类" width="100">
					</el-table-column> -->
					<el-table-column align="center" prop="zhaungtai" label="订单状态" min-width="100">
					</el-table-column>
					<el-table-column align="center" prop="updatedTime" label="订单状态起始时间" width="180">
					</el-table-column>
					<el-table-column align="center" prop="fangan" label="设计方案">
						<template slot-scope="scope">
							<span v-if="scope.row.fangan=='--'">--</span>
							<span @click="sheJiFangAn(scope.row.oid)" v-if="scope.row.fangan!='--'" style="cursor: pointer;" >{{scope.row.fangan}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="zhongzhi" align="center" label="订单是否终止" width="110">
						<template slot-scope="scope">
							<span v-if="scope.row.zhongzhi==true" style="cursor: pointer !important;color: #af9f73;"
								@click="zhongZhi(scope.$index,scope.row.oid)">确认</span>
							<span v-if="scope.row.zhongzhi==false" style="color: black;">--</span>
						</template>
					</el-table-column>
					<el-table-column prop="xiazai" align="center" label="预览">
						<template slot-scope="scope">
							<span v-if="scope.row.xiazai==false" style="color: black;">--</span>
							<!-- <span @click="downloadOrder(scope.row.oid)" v-if="scope.row.xiazai==true" style="color: #af9f73;cursor: pointer;">下载</span> -->
							<span @click="downloadOrder(scope.row.oid)" v-if="scope.row.xiazai==true" style="color: #af9f73;cursor: pointer;">预览</span>
						</template>
					</el-table-column>
					<el-table-column min-width="120"  prop="" align="center"  label="订单详情">
						<template slot-scope="scope">
							<span v-if="scope.row.zhaungtai=='订单未提交'"  style="cursor: not-allowed !important;color: lightgray;">订单进程记录</span>
						    <span v-if="scope.row.zhaungtai!='订单未提交'" @click="goOrderJinDu(scope.row.oid)" style="cursor: pointer !important;">订单进程记录</span>
						</template>
					</el-table-column>
					
					<el-table-column width="150" align="center"  label="删除">
						<template slot-scope="scope">
							<img v-if="scope.row.shanchu==true" src="../../assets/ysmimg/delete.png"
								:class="scope.row.oid" @mouseenter="enterImg(scope.row.oid)"
								@mouseleave="leaveImg(scope.row.oid)" @click="delOrder(scope.$index,scope.row.oid)" />
							<i v-if="scope.row.shanchu==false" style="cursor: not-allowed;color: lightgray;"
								class="el-icon-delete"></i>
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页 -->
				<div class="block">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[5, 10, 20, 30]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="ordersData.length">
					</el-pagination>
				</div>
			</div>

		</div>
			<div class="f">Copyright&copy;www.techlion.com.cn All Rights Reserved<br/>山东泰克莱恩 0513-88762550</div>
	</div>
</template>

<script>
	import {
		Message
	} from 'element-ui';
	export default {
		data: function() {
			return {
				statusOptions:[],
				radioValue: 0, //单选按钮的取值
				conditionForm: { //表单绑定的对象
					query: '', //搜索
					status: '', //订单状态
					doctorId: '',
					fenlei: 0,
					productId: 2,
					status1: '',
				},
				type: 2,
				ordersData: [],
				currentPage: 1, //当前页码
				pagesize: 5, //页面大小
			}
		},
		methods: {
			//单击添加订单
			goAddOrder(){
				sessionStorage.removeItem("oid");
				//this.backIndex=2;
				//document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue")
				//document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1 visible")
				 this.$store.commit("setBackLocation","/ysmmain/ysmlatestorder");
				
				 document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue")
				 document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1 visible")
				this.$router.push("/ysmmain/ysmorder/ysmorderone");
			},
			//选择不同的分类
			changeFenLei(fl){
				this.conditionForm.status1='';
				this.statusOptions=[];
				console.log(fl);
				if(fl==0){
					let obj0={"label":"订单未提交","value":0};
					let obj1={"label":"订单审核中","value":1};
					let obj2={"label":"订单未通过","value":2};
					let obj3={"label":"设计方案确认中","value":3};
					let obj4={"label":"订单待付款","value":4};
					let obj5={"label":"订单生产中","value":5};
					let obj6={"label":"订单发货中","value":6};
					let obj7={"label":"收货完成","value":7};
					let obj8={"label":"订单终止","value":8};
					this.statusOptions.push(obj0);
					this.statusOptions.push(obj1);
					this.statusOptions.push(obj2);
					this.statusOptions.push(obj3);
					this.statusOptions.push(obj4);
					this.statusOptions.push(obj5);
					this.statusOptions.push(obj6);
					this.statusOptions.push(obj7);
					this.statusOptions.push(obj8);
				}else if(fl==1){
					let obj0={"label":"订单未提交","value":0};
					let obj1={"label":"订单审核中","value":1};
					let obj2={"label":"订单未通过","value":2};
					
					this.statusOptions.push(obj0);
					this.statusOptions.push(obj1);
					this.statusOptions.push(obj2);
				}else if(fl==2){
					let obj8={"label":"订单终止","value":8};
					this.statusOptions.push(obj8);
				}else if(fl==3){
						let obj3={"label":"设计方案确认中","value":3};
						this.statusOptions.push(obj3);
				}else if(fl==4){
					let obj4={"label":"订单待付款","value":4};
					let obj5={"label":"订单生产中","value":5};
					this.statusOptions.push(obj4);
					this.statusOptions.push(obj5);
				}else if(fl==5){
					let obj6={"label":"订单发货中","value":6};
						let obj7={"label":"收货完成","value":7};
					this.statusOptions.push(obj6);
					this.statusOptions.push(obj7);
				}
			},
			//单击设计方案
			sheJiFangAn(oid){
				sessionStorage.setItem("oid",oid);
				this.$router.push("/ysmmain/ysmorderfangan");
			},
			changeRed({row}) {
				if (row.unread ==2) { // 变颜色的条件
					return{
					color:"#af9f73",
					  fontWeight:"bolder"// 这个return的就是样式 可以是color 也可以是backgroundColor
					}
				}
			},
			//移入到删除上
			enterImg(cl) {

				document.getElementsByClassName(cl)[0].setAttribute("src", require('../../assets/ysmimg/hoverdelete.png'))
				// this.src=require('../../assets/ysmimg/hoverdelete.png');
			},
			leaveImg(cl) {

				document.getElementsByClassName(cl)[0].setAttribute("src", require('../../assets/ysmimg/delete.png'))
				// this.src=require('../../assets/ysmimg/hoverdelete.png');
			},
			//搜索
			onSubmit() {
				this.currentPage=1;
				console.log(this.conditionForm);
				if (this.conditionForm.status1 === "") {
					this.conditionForm.status = -1;
				} else {
					this.conditionForm.status = this.conditionForm.status1;
				}
				this.conditionForm.fenlei=this.radioValue;
				this.$axios.post("/client/order/queryOrderList", JSON.stringify(this.conditionForm), {
					headers: {
						"Content-type": "application/json"
					}
				}).then(res => {
					console.log(res.data.data);
					this.ordersData = res.data.data;
				}).catch(err => {
					this.$message.error("添加失败");
				})
			},
			//分页相关的
			handleSizeChange(val) {
				this.pagesize = val;
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				console.log(`当前页: ${val}`);
			},
			//删除订单
			delOrder(index, oid) {
				this.$confirm('此操作将永久删除该订单, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axios.get("/client/order/delOrder", {
						params: {
							oid: oid
						}
					}).then(res => {
						console.log(res.data)
						if (res.data.code == 200) {
							this.$message({
								type: 'success',
								message: '删除成功!',
								offset: 300,
								duration: 1000
							});
							this.ordersData.splice((this.currentPage-1)*this.pagesize+index, 1);
							this.currentPage=1;
						} else {
							this.$message({
								type: 'error',
								message: '删除失败!',
								offset: 300,
								duration: 1000
							});
						}
					}).catch(err => {
						alert("删除失败,请联系管理员");
					});

				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除',
						offset: 300,
						duration: 1000
					});
				});
			},
			//编辑订单
			editOrder(oid,zhaungtai) {
				sessionStorage.setItem("oid", oid);
				sessionStorage.setItem("zhaungtai",zhaungtai);
			
				if(zhaungtai=='订单未提交'){
					this.$router.push("/ysmmain/ysmorder/ysmorderone");
				}else{
					this.$router.push("/ysmmain/ysmorder/ysmordereleven2");
				}
				
			},
			//终止订单
			zhongZhi(index,oid) {
				this.$confirm('确认终止订单,本次订单您将无法再次编辑操作,如您想继续操作本次订单请点击 "取消" 按钮?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axios.get("/client/order/stopOrder", {
						params: {
							oid: oid
						}
					}).then(res => {
						console.log(res.data)
						if (res.data.code == 200) {
							this.$message({
								message: "终止成功",
								type: "success",
								offset: 300
							});
							this.ordersData[index].zhongzhi=false;
							
						} else {
							this.$message({
								type: 'error',
								message: '删除失败!',
								offset: 300,
								duration: 1000
							});
						}
					}).catch(err => {
						alert("删除失败,请联系管理员");
					});


				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消终止',
						duration: 1000,
						offset: 300
					});

				});
			},
			//去订单进度页面
			goOrderJinDu(oid){
				console.log(oid);
				sessionStorage.setItem("oid",oid);
				this.$router.push("/ysmmain/ysmorderjindu");
			},
			//订单下载
			downloadOrder(oid){
				// window.open("http://120.26.66.95:8088/client/order/downloadOrder?oid="+oid)
				sessionStorage.setItem("yloid",oid);
				this.$router.push("/ysmmain/ysmorderyulan");
			}
		},
		mounted:function(){
			sessionStorage.removeItem("zhaungtai");
			let obj00={"label":"所有订单","value":-1};
			let obj0={"label":"订单未提交","value":0};
			let obj1={"label":"订单审核中","value":1};
			let obj2={"label":"订单未通过","value":2};
			let obj3={"label":"设计方案确认中","value":3};
			let obj4={"label":"订单待付款","value":4};
			let obj5={"label":"订单生产中","value":5};
			let obj6={"label":"订单发货中","value":6};
			let obj7={"label":"收货完成","value":7};
			let obj8={"label":"订单终止","value":8};
			this.statusOptions.push(obj00);
			this.statusOptions.push(obj0);
			this.statusOptions.push(obj1);
			this.statusOptions.push(obj2);
			this.statusOptions.push(obj3);
			this.statusOptions.push(obj4);
			this.statusOptions.push(obj5);
			this.statusOptions.push(obj6);
			this.statusOptions.push(obj7);
			this.statusOptions.push(obj8);
			document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1")
			document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue")
			document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2")

		},
		created: function() {
			
			
           //=================================
			this.conditionForm.doctorId = JSON.parse(sessionStorage.getItem("loginInfo")).id;
			//查询默认条件下的订单信息
			this.conditionForm.status = -1;
			this.conditionForm.query = "";
			this.conditionForm.fenlei = 0;
			this.$axios.post("/client/order/queryOrderList", JSON.stringify(this.conditionForm), {
				headers: {
					"Content-type": "application/json"
				}
			}).then(res => {
				console.log(res.data);
				this.ordersData = res.data.data;
			}).catch(err => {
				this.$message.error("添加失败");
			})
			//将当前路径存储到backLocation
			this.$store.commit("setBackLocation", "/ysmmain/ysmlatestorder");
		}
	}
</script>

<style scoped="scoped">
	.ysm-latest-order {
		width: 100%;
		height: 100%;
		background-color: transparent;
		overflow-y: auto;
		position: relative;
		margin-bottom: 1.875rem;
	}

	.order {
		position: relative;
		background-color: white;
		width: 100%;
		min-height: 36.85rem !important;
		margin-top: 18.75rem;
		margin-bottom: 38.75rem;
		padding-top: 1.25rem;
	}

	.f {
		position: relative;
		background-color: #444;
		padding: 10px 0 10px 0;
		color: #fff;
		bottom: 0rem;
	}

	/* 条件 */
	.conditions {
		box-sizing: border-box;
		background-color: #f0f0ee;
		/* padding: 10px 20px 20px 20px; */
		width: 80%;
		/* height: 110px; */
		margin: 0px auto;
		display: flex;
		align-items: center;
		padding-top: 5px;
		padding-bottom: 5px;
	}

	.primary {
		margin: 0px 0px 1px;
		padding: 6px 12px 6px 12px;
		background-color: #af9f73 !important;
		border-color: #af9f73 !important;
		color: #fff !important;
		border-radius: 0px;
		width: 5.125rem;
		height: 2.3rem;
	}
	.block{
		margin-bottom: 5px;
	}
	.big{
		font-weight: bolder;
		color:#af9f73 !important;
	}
	.f {
		position: relative;
		background-color: #444;
		padding: 10px 0 10px 0;
		color: #fff;
		bottom: 0rem;
	}
	#createNewOrder{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	#createNewOrder span{
		color:#af9f73 !important;
		cursor: pointer;
		display: inline-block;
		margin-top: 2px;
	}
</style>
